import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const handleDisconnect = async () => {
    room!.disconnect();
    const classID = localStorage.getItem('class');
    const teacherID = localStorage.getItem('teacher');
    if (classID && teacherID) {
      // await fetch('https://qaapi.aarshilearning.com/liveclass/join/', {
      //   method: 'POST',
      //   headers: {
      //     'content-type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     teacherStudent: teacherID,
      //     classID: classID,
      //     interactionStatus: 0,
      //   }),
      // })
      //   .then(res => res.json())
      //   .then(
      //     result => {
      //       console.log(result);
      //     },
      //     error => {
      //       console.log(error);
      //     }
      //   );
    }
  };
  return (
    <Button onClick={handleDisconnect} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
