import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import queryString from 'query-string';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [teacher_student, setTeacherStudent] = useState<string>('');
  const [className, setClassName] = useState<string>('');
  const [classStatus, setClassStatus] = useState<boolean>(false);
  const [completedStatus, setCompletedStatus] = useState<boolean>(false);
  const [waitStatus, setWaitStatus] = useState<boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    localStorage.clear();
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
    async function fetchCourseStudent() {
      const searchKeys = queryString.parse(window.location.search) || {};
      setName(searchKeys['userid'] as string);
      localStorage.setItem('class', 'Aarshi Call');
      setClassStatus(true);
      setCompletedStatus(false);
      setWaitStatus(false);
      setClassName('Aarshi Call');
      setTeacherStudent(searchKeys['userid'] as string);
      localStorage.setItem('teacher', 'rakesh');
      // await fetch('https://qaapi.aarshilearning.com/teachers/checkStudentTeacher/' + searchKeys['userid'])
      //   .then(res => res.json())
      //   .then(
      //     result => {
      //       console.log(result);
      //       const teacher_student =
      //         result['data'] && (result['data']['teacher'] || result['data']['student']) ? result['data']['name'] : '';
      //       setName(teacher_student);
      //       const teacherStudentID = searchKeys['userid'] ? searchKeys['userid'] : '';
      //       if (result['data'] && result['data']['teacher']) {
      //         localStorage.setItem('teacher', teacherStudentID as string);
      //       }
      //       setTeacherStudent(teacherStudentID as string);
      //     },
      //     // Note: it's important to handle errors here
      //     // instead of a catch() block so that we don't swallow
      //     // exceptions from actual bugs in components.
      //     error => {
      //       console.log(error);
      //     }
      //   );
      // await fetch('https://qaapi.aarshilearning.com/liveclass/getClassDetails/' + searchKeys['class'])
      //   .then(res => res.json())
      //   .then(
      //     result => {
      //       console.log(result);
      //       const className = result['data'] && result['data']['liveclass'] ? result['data']['liveclass']['name'] : '';
      //       let classStatus = result['data'] && parseInt(result['data']['interactionStatus']) > 0 ? true : false;
      //       if (
      //         result['data'] &&
      //         result['data']['teacher'].toString() === searchKeys['userid']?.toString() &&
      //         !result['completed']
      //       ) {
      //         classStatus = true;
      //       }
      //       localStorage.setItem('class', searchKeys['class'] as string);
      //       setClassStatus(classStatus);
      //       setCompletedStatus(result['data']['completed']);
      //       setWaitStatus(result['data']['wait']);
      //       setClassName(className);
      //     },
      //     // Note: it's important to handle errors here
      //     // instead of a catch() block so that we don't swallow
      //     // exceptions from actual bugs in components.
      //     error => {
      //       console.log(error);
      //     }
      //   );
    }
    fetchCourseStudent();
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      console.log(roomName);
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          courseName={className}
          teacherStudent={teacher_student}
          classStatus={classStatus}
          completed={completedStatus}
          wait={waitStatus}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomName={roomName}
          courseName={className}
          teacherStudent={teacher_student}
          setStep={setStep}
        />
      )}
    </IntroContainer>
  );
}
