import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import queryString from 'query-string';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  courseName: string;
  teacherStudent: string;
  classStatus: boolean;
  wait: boolean;
  completed: boolean;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  roomName,
  setName,
  setRoomName,
  teacherStudent,
  classStatus,
  wait,
  completed,
  courseName,
  handleSubmit,
}: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  // const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
  console.log(name);
  console.log(courseName);
  console.log(roomName);
  const hasUsername = name; //searchKeys && searchKeys['displayName'] ? searchKeys['displayName'].toString() : '';
  setRoomName(courseName);
  setName(name);
  setRoomName(roomName);
  // if (hasUsername) {
  //   setName(hasUsername);
  // }
  console.log(hasUsername, teacherStudent, courseName, classStatus, !completed, wait);
  if (hasUsername && teacherStudent && courseName && classStatus && (!completed || wait)) {
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Join Interactive Session - {courseName}
        </Typography>
        <Typography variant="body1">
          {/* {hasUsername
            ? "Enter the name of a room you'd like to join."
            : "Enter your name and the name of a room you'd like to join"} */}
        </Typography>
        <form onSubmit={handleSubmit} id="roomNameScreen">
          <div className={classes.inputContainer}>
            {!hasUsername && (
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  Your Name
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            )}
            <div className={classes.textFieldContainer}>
              {/* <InputLabel shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              disabled = {true}
              value={roomName}
              onChange={handleRoomNameChange}
            /> */}
            </div>
          </div>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name}
              className={classes.continueButton}
            >
              Continue
            </Button>
          </Grid>
        </form>
      </>
    );
  } else {
    if (completed) {
      return (
        <>
          <Typography style={{ textAlign: 'center', margin: '20%' }} variant="h5" className={classes.gutterBottom}>
            Doubt Clearing session is completed
          </Typography>
        </>
      );
    } else if (wait) {
      return (
        <>
          <Typography style={{ textAlign: 'center', margin: '20%' }} variant="h5" className={classes.gutterBottom}>
            Doubt Clearing session is not started yet...
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography style={{ textAlign: 'center', margin: '20%' }} variant="h5" className={classes.gutterBottom}>
            Please wait...
          </Typography>
        </>
      );
    }
  }
}
